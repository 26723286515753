export const menuItems = [
    // ...
    {
      title: 'Menu',
      url: '/services',
      submenu: [
        {
          title: 'Dashboard',
          url: '/',
        },
        {
          title: 'Einkaufen',
          url: '/einkaufen',
        },
        {
          title: 'Klingel',
          url: '/klingel',
        },
      ],
    },
    // ...
  ];