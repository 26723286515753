import React from 'react';
import klingel from '../../res/klingel.png';

const User = ({ userData, ringUserFn }) => {
  return (
    <div className={`klingel-${userData.status}`}>
      {userData.name}
      <div className="button-container">
        <button
          className="button-43-yellow"
          onClick={() => ringUserFn(userData.name)}
          aria-label={`Ring ${userData.name}`}
        >
          <img style={{ width: "25px" }} src={klingel} alt="ring" />
        </button>
      </div>
    </div>
  );
};

export default User;
