import { menuItems } from './items';
import MenuItems from './MenuItems';

const Navbar = () => {
  return (
    <nav>
      <ul className="menus">
        {menuItems.map((menu) => (
          <MenuItems items={menu} key={menu.id || menu.title} /> 
        ))}
      </ul>
    </nav>
  );
};

export default Navbar;
