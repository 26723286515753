import { useEffect, useState, useContext } from "react";
import ListItem from "../components/Shopping/ListItem";
import { TokenContext, URLContext } from "../context/TokenContext";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import loading from "../res/loading.gif";
import trash from "../res/trash.png";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

export function Shopping() {
  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState("");
  const [loaded, setLoaded] = useState(false);
  const { token } = useContext(TokenContext);

  const { URL } = useContext(URLContext);

  function restRequestItems() {
    setTimeout(() => {
      fetch(URL + "/orders", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setItems(data);
          setLoaded(true);
        });
    }, 250);
  }

  function restAddItem(text) {
    setLoaded(false);
    fetch(URL + "/orders", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        text: text,
      }),
    }).then(() => {
      NotificationManager.success(text + " hinzugefügt!", "");
    });
    restRequestItems();
  }

  function restSortItem(idFrom, idTo) {
    setLoaded(false);
    fetch(URL + "/orders/sort", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        idFrom: idFrom,
        idTo: idTo,
      }),
    });
    restRequestItems();
  }

  function restDeleteItem(id) {
    console.log("delete id " + id);
    setLoaded(false);
    fetch(URL + "/orders" + "/" + id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    restRequestItems();
  }

  function restUpdateItem(id, text) {
    setLoaded(false);
    fetch(URL + "/orders", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        id: id,
        text: text,
      }),
    });
    restRequestItems();
  }

  function restDeleteAllItems() {
    setLoaded(false);
    fetch(URL + "/orders", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then(() => {
      NotificationManager.success("Alles gelöscht!", "");
    });
    restRequestItems();
  }

  useEffect(() => {
    if (token !== "") restRequestItems();
    const interval = setInterval(() => {
      if (token !== "") restRequestItems();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  if (token === "") return <div></div>;

  return (
    <div className="shopping-page">
      <NotificationContainer />
      <div className="shopping-container">
        <input
          className="shopping-input"
          value={newItem}
          onChange={(event) => setNewItem(event.target.value)}
          placeholder="Item name..."
        />
        
        <button
          className="add-item-btn"
          onClick={() => {
            restAddItem(newItem);
            setNewItem("");
          }}
        >
          +
        </button>
      </div>

      {!loaded ? (
        <img src={loading} alt="loading..." className="loading-image" />
      ) : (
        <div>
          <DragDropContext
            onDragEnd={(result) => {
              console.log(result);
              restSortItem(result.source.index, result.destination.index);
            }}
          >
            <Droppable droppableId="bla">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="droppable-container"
                >
                  {items.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={"" + item.id}
                      index={item.sortId}
                    >
                      {(provided) => (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          className="draggable-item"
                        >
                          <ListItem
                            item={item}
                            deleteFn={restDeleteItem}
                            updateFn={restUpdateItem}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {items.length > 0 && (
            <div className="delete-all-container">
              <Popup
                trigger={
                  <button className="delete-all-btn">
                    <img
                      style={{ width: "64px" }}
                      src={trash}
                      alt="delete"
                    />
                  </button>
                }
                modal
              >
                {(close) => (
                  <span>
                    {" "}
                    Alles löschen?{" "}
                    <button
                      className="delete-confirm-btn"
                      onClick={() => {
                        restDeleteAllItems();
                        close();
                      }}
                    >
                      Löschen
                    </button>
                  </span>
                )}
              </Popup>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Shopping;
