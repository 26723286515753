import { useContext, useState, useEffect } from "react";
import { TokenContext, URLContext } from "../../context/TokenContext";

const LoginPanel = () => {
  const { URL } = useContext(URLContext);
  const [password, setPassword] = useState("");
  const { token, setToken } = useContext(TokenContext);
  const [loading, setLoading] = useState(false);

  // Handles the login request
  function loginRequest(pw) {
    if (!pw) return;  // Prevent empty password submission

    setLoading(true);
    fetch(URL + "/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password: pw,
      }),
    })
      .then((res) => res.json())
      .then((loginPayload) => {
        setToken(loginPayload.token);
        console.log("set token to (" + loginPayload.token + ")");
      })
      .catch((error) => {
        console.error("Login failed:", error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false after the request completes
      });
  }

  // Handles logout
  function logout() {
    setToken("");
  }

  // Optionally log password value
  useEffect(() => {
    console.log(password);
  }, [password]);

  return (
    <div style={{ margin: "20px" }}>
      <input
        type="password"
        value={password}
        onChange={(event) => setPassword(event.target.value)}
        placeholder="Enter password"
      />
      <button
        onClick={() => loginRequest(password)}
        disabled={loading || !password}  // Disable button while loading or empty password
      >
        {loading ? "Logging in..." : "Log in"}
      </button>
      <p></p>
      {token !== "" ? (
        <button onClick={logout}>Logout</button>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default LoginPanel;
