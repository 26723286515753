import React, { useState } from 'react';
import trash from '../../res/trash.png';
import edit from '../../res/edit.png';
import Popup from 'reactjs-popup';
import { isMobile } from 'react-device-detect';

const ListItem = ({ item, deleteFn, updateFn }) => {
  const [editedItem, setEditedItem] = useState(item.text);

  const css = isMobile ? "-mobile" : "";
  const addMargin = isMobile ? "-12px" : "";

  return (
    <div className={`list-item${css}`} draggable>
      
      <div>{item.text}</div>
      <div style={{display: "flex", justifyContent: ""}}>
        <div className="action-buttons" style={{ width: "20px", marginRight: "10px", display: "flex", columnGap: "10px" }}>
        
        {/* Edit Button with Popup */}
        <Popup 
          trigger={
            <button className={`button-43-yellow${css}`}>
              <img 
                style={{ width: "25px", marginTop: addMargin }} 
                src={edit} 
                alt="Edit item" 
              />
            </button>
          } 
          modal
        >
          {close => (
            <div>
              <h3>Text Ändern</h3>
              <input 
                className="shopping-input" 
                value={editedItem} 
                onChange={(event) => setEditedItem(event.target.value)} 
              />
              <button 
                className="button-43-red" 
                onClick={() => { updateFn(item.id, editedItem); close(); }}
              >
                OK
              </button>
            </div>
          )}
        </Popup>

        {/* Delete Button */}
        <button 
          className="button-43-red" 
          onClick={() => deleteFn(item.id)}
        >
          <img 
            style={{ width: "25px", marginTop: addMargin }} 
            src={trash} 
            alt="Delete item" 
          />
        </button>
      </div></div>


    </div>
  );
};

export default ListItem;
