import { useEffect, useState, useContext } from "react";
import User from '../components/Klingel/User';
import { TokenContext, URLContext } from "../context/TokenContext";
import loading from '../res/loading.gif';
import klingel from '../res/klingel.png';
import { NotificationContainer, NotificationManager } from 'react-notifications';

export function Klingel() {
  const [users, setUsers] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const { token } = useContext(TokenContext);
  const { URL } = useContext(URLContext);

  const restRequestUserdata = async () => {
    try {
      const response = await fetch(`${URL}/klingel/userInfo`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setUsers(data);
        setLoaded(true);
      } else {
        throw new Error('Failed to fetch user data');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const restRingAll = async () => {
    try {
      setLoaded(false);
      const response = await fetch(`${URL}/klingel/ring`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      if (response.ok) {
        setLoaded(true);
      } else {
        throw new Error('Failed to ring all users');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const restRingUser = async (name) => {
    try {
      setLoaded(false);
      const response = await fetch(`${URL}/klingel/ring/${name}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      if (response.ok) {
        NotificationManager.success(`Es hat bei ${name} geklingelt`, '');
      } else {
        throw new Error(`Failed to ring user ${name}`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoaded(true);
    }
  };

  // Fetch user data on interval
  useEffect(() => {
    if (token) {
      const interval = setInterval(() => {
        restRequestUserdata();
      }, 2000);
      return () => clearInterval(interval); // Clean up the interval on component unmount
    }
  }, [token, URL]);

  if (!token) return <div>Please log in to access the Klingel.</div>;

  return (
    <div>
      <NotificationContainer />
      {!loaded ? (
        <img src={loading} style={{ width: "200px" }} alt="loading.." />
      ) : (
        <div style={{ margin: "20px" }}>
          {users.length > 0 && (
            <div style={{ width: "100px", height: "80px" }}>
              <button className="button-43-yellow" onClick={restRingAll}>
                <img style={{ width: "64px" }} src={klingel} alt="Klingeln" />
              </button>
            </div>
          )}
          {users.map((userData) => (
            <User key={userData.id} userData={userData} ringUserFn={restRingUser} />
          ))}
        </div>
      )}
    </div>
  );
}
