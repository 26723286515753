import { useContext, useEffect, useState } from 'react';
import { TokenContext, URLContext } from '../context/TokenContext';

// Static URL can be moved outside of the component as it doesn't change
const INTERNET_URL = "http://dashapi.lixenfeld.de";

export function Dashboard() {
    const { token } = useContext(TokenContext);
    const { URL, setURL } = useContext(URLContext);
    const [urlSet, setURLSet] = useState(false);

    // Effect hook runs only when URL or urlSet changes
    useEffect(() => {
        if (!urlSet && !URL) {
            setURL(INTERNET_URL);
            setURLSet(true);
        }
    }, [URL, urlSet, setURL]);

    // If there's no token, render an empty state or redirect to login
    if (!token) {
        return <div>Please log in to view the dashboard.</div>; // Or a loading spinner
    }

    return (
        <div style={{ margin: "20px" }}>
            <iframe 
                style={{ height: 231, width: "100%" }} 
                src="https://www.wetter.de/widget/heute/u1hgwf/false/"
                title="Weather Widget"
            />
        </div>
    );
}
