import { useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from "./Dropdown";
import loading from "../../res/list.png";

const MenuItems = ({ items }) => {
  const [dropdown, setDropdown] = useState(false);
  const toggleDropdown = () => setDropdown(prev => !prev);

  return (
    <li className="menu-items">
      {items.submenu ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={toggleDropdown}
          >
            <img src={loading} style={{ width: "64px" }} alt="submenu loading indicator" />
          </button>
          <Dropdown
            submenus={items.submenu}
            dropdown={dropdown}
            setDropdown={setDropdown}
          />
        </>
      ) : (
        <Link to={items.url}>{items.title}</Link>
      )}
    </li>
  );
};

export default MenuItems;
