import React, { useState, useMemo } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import { TokenContext, URLContext } from "./context/TokenContext";
import { Dashboard } from "./pages/Dashboard";
import Shopping from "./pages/Shopping";
import { Login } from "./pages/Login";
import { Klingel } from "./pages/Klingel";
import Header from "./components/App/Header";

function App() {
  const [token, setToken] = useState("");
  const tokenProviderValue = useMemo(() => ({ token, setToken }), [token, setToken]);

  const [URL, setURL] = useState("");
  const urlProviderValue = useMemo(() => ({ URL, setURL }), [URL, setURL]);

  const renderLoginLink = () => (
    <div className="link-login-container">
      <Link className={`link-nav-login-${token === "" ? "out" : "in"}`} to="/login">
        {token === "" ? "Einloggen" : "Eingeloggt"}
      </Link>
    </div>
  );

  return (
    <TokenContext.Provider value={tokenProviderValue}>
      <URLContext.Provider value={urlProviderValue}>
        <div className="nav-main">
          {token && <Header />}
          {renderLoginLink()}
        </div>

        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/einkaufen" element={<Shopping />} />
          <Route path="/login" element={<Login />} />
          <Route path="/klingel" element={<Klingel />} />
        </Routes>
      </URLContext.Provider>
    </TokenContext.Provider>
  );
}

export default App;
