import { Link } from 'react-router-dom';

const Dropdown = ({ submenus, dropdown, setDropdown }) => (
  <ul className={`dropdown ${dropdown ? "show" : ""}`}>
    {submenus.map((submenu) => (
      <li key={submenu.url} className="menu-items">
        <Link to={submenu.url} onClick={() => setDropdown(false)}>
          {submenu.title}
        </Link>
      </li>
    ))}
  </ul>
);

export default Dropdown;
